<template>
  <div :class="`quote ${advanced?.class ?? ''}`">
    <div class="quote_body">
      <blockquote
        class="quote_text typo-format"
        v-html="finalText"
      ></blockquote>
      <p class="quote_author">{{ author }}</p>
    </div>
    <div v-if="finalAuthorPicture" class="quote_author-picture">
      <UiImage v-if="finalAuthorPicture" v-bind="finalAuthorPicture" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { marked } from 'marked'
import type { TImage } from '~/components/ui/image/image.type'
import UiImage from '~/components/ui/image/image.vue'
import './quote.css'
import { type TQuote } from './quote.type.js'

const props = withDefaults(defineProps<TQuote>(), {})

const finalAuthorPicture = props.authorPicture as TImage,
  finalText = computed(() => marked.parseInline(props.text ?? ''))

if (finalAuthorPicture) {
  finalAuthorPicture.imgix = {
    ar: '1:1',
    fit: 'crop',
  }
}
</script>
